// JS Controller for global methods and functions

import { Controller } from "stimulus"

import Choices from "choices.js"
import flatpickr from "flatpickr"
import { Portuguese } from "flatpickr/dist/l10n/pt.js"

export default class extends Controller {

  static targets = [ "searchType", "eventManagersWrapper", "removeEventEditionEventManagerButton", "enableArticlesCategories", "articleCategoriesDiv", "articleCategories", "eventEditionEventManagerCategory", "applyAgreementSignDefaultWrapper", "modality", "addressDiv" ]

  initialize() {
    this.flatpickrConfig = {
      enableTime: true,
      altInput: true,
      altFormat: "j/m/Y H:i",
      locale: Portuguese
    }

    this.choiceConfigWithoutRemoveItem = {
      searchResultLimit: 15,
      searchChoices: true,
      position: "bottom",
      removeItems: false,
      removeItemButton: false,
      sorter: function() { return },
      noChoicesText: 'Nenhum item para ser selecionado',
      itemSelectText: "Pressione enter para adicionar",
      loadingText: "Carregando..."
    }

    this.choiceConfig = {
      searchResultLimit: 15,
      searchChoices: true,
      position: "bottom",
      removeItems: true,
      removeItemButton: true,
      sorter: function() { return },
      noChoicesText: 'Nenhum item para ser selecionado',
      itemSelectText: "Pressione enter para adicionar",
      loadingText: "Carregando..."
    }

    this.loadCKEditor()
  }

  connect() {
    if ((document.body.getAttribute("data-action").includes("new")) || (document.body.getAttribute("data-action").includes("edit"))) {
      flatpickr(".datetime-select-input", this.flatpickrConfig)
    }
    if (this.hasEnableArticlesCategoriesTarget) {
      this.changeEnableArticlesCategories()
    }

    if (this.hasArticleCategoriesTarget){
      this.eventEditionArticleCategories = new Choices(this.articleCategoriesTarget, this.choiceConfig)
      this.changeArticlesCategoriesToEventEditionEventManager()
    }
    this.checkVisibleEventEditionEventManagerArticleCategoriesDivs('load')

    if (this.hasModalityTarget){
      this.changeModality()
    }
  }

  enableCertificate(event) {
    if (event.currentTarget.checked) {
      document.querySelector("#event_edition_certificate_template").closest("div.flex").classList.remove("is-display-none")
      document.querySelector("#event_edition_certificate_is_free").closest("div.flex").classList.remove("is-display-none")
      document.querySelector("#event_edition_certificate_workload").closest("div.flex").classList.remove("is-display-none")
      document.querySelector("#event_edition_certificate_enable_verse").closest("div.flex").classList.remove("is-display-none")
      document.querySelector("#event_edition_certificate_percentage").closest("div.flex").classList.remove("is-display-none")
      document.querySelector("#event_edition_verse_content_ids").closest("div.flex").classList.remove("is-display-none")
      document.querySelector("#event_edition_certificate_communication").closest("div.flex").classList.remove("is-display-none")
      document.querySelector("#event_edition_certificate_notification_sent").closest("div.flex").classList.remove("is-display-none")
      document.querySelector("#event_edition_certificate_notification_sent_at").closest("div.flex").classList.remove("is-display-none")
      document.querySelector(".title-certificade-paid").classList.remove("is-display-none")
      document.querySelector(".title-certificade-free").classList.remove("is-display-none")
    } else {
      document.querySelector("#event_edition_certificate_template").closest("div.flex").classList.add("is-display-none")
      document.querySelector("#event_edition_certificate_is_free").closest("div.flex").classList.add("is-display-none")
      document.querySelector("#event_edition_certificate_workload").closest("div.flex").classList.add("is-display-none")
      document.querySelector("#event_edition_certificate_enable_verse").closest("div.flex").classList.add("is-display-none")
      document.querySelector("#event_edition_certificate_percentage").closest("div.flex").classList.add("is-display-none")
      document.querySelector("#event_edition_verse_content_ids").closest("div.flex").classList.add("is-display-none")
      document.querySelector("#event_edition_certificate_communication").closest("div.flex").classList.add("is-display-none")
      document.querySelector("#event_edition_certificate_notification_sent").closest("div.flex").classList.add("is-display-none")
      document.querySelector("#event_edition_certificate_notification_sent_at").closest("div.flex").classList.add("is-display-none")
      document.querySelector(".title-certificade-paid").classList.add("is-display-none")
      document.querySelector(".title-certificade-free").classList.add("is-display-none")
    }
  }

  enableFreeCertificate(event) {
    if (event.currentTarget.checked) {
      document.querySelector("#event_edition_certificate_workload").closest("div.flex").classList.remove("is-display-none")
      document.querySelector("#event_edition_certificate_enable_verse").closest("div.flex").classList.remove("is-display-none")
      document.querySelector("#event_edition_verse_content_ids").closest("div.flex").classList.remove("is-display-none")
      document.querySelector("#event_edition_certificate_notification_sent").closest("div.flex").classList.remove("is-display-none")
      document.querySelector("#event_edition_certificate_notification_sent_at").closest("div.flex").classList.remove("is-display-none")
    } else {
      document.querySelector("#event_edition_certificate_workload").closest("div.flex").classList.add("is-display-none")
      document.querySelector("#event_edition_certificate_enable_verse").closest("div.flex").classList.add("is-display-none")
      document.querySelector("#event_edition_verse_content_ids").closest("div.flex").classList.add("is-display-none")
      document.querySelector("#event_edition_certificate_notification_sent").closest("div.flex").classList.add("is-display-none")
      document.querySelector("#event_edition_certificate_notification_sent_at").closest("div.flex").classList.add("is-display-none")
    }
  }

  enableCertificateVerse(event) {
    if (event.currentTarget.checked) {
      document.querySelector("#event_edition_verse_content_ids").closest("div.flex").classList.remove("is-display-none")
    } else {
      document.querySelector("#event_edition_verse_content_ids").closest("div.flex").classList.add("is-display-none")
    }
  }

  enableAgreementSign(event) {
    if (event.currentTarget.checked) {
      document.querySelector("#event_edition_agreement_sign_term").closest("div.flex").classList.remove("is-display-none")
      this.applyAgreementSignDefaultWrapperTarget.classList.remove("is-display-none")
    } else {
      document.querySelector("#event_edition_agreement_sign_term").closest("div.flex").classList.add("is-display-none")
      this.applyAgreementSignDefaultWrapperTarget.classList.add("is-display-none")
    }
  }

  changeEnabled(event) {
    document.querySelector("#event_edition_blocked").disabled = event.currentTarget.checked
  }

  changeBlocked(event) {
    document.querySelector("#event_edition_enabled").disabled = event.currentTarget.checked
  }

  startZipDownload(event) {
    var r = confirm(`Confirmar download?`)
    if (r == true) {
      event.target.style.pointerEvents = "none"
      fetch("/edicoes-de-evento/exportar.csv?" + new URLSearchParams({
        q: JSON.stringify({
          start_at_gteq: document.querySelector("#start_at_gteq").value,
          start_at_lteq: document.querySelector("#start_at_lteq").value,
          search_type: document.querySelector("#search_type").value,
          title_i_cont: document.querySelector("#q_title_i_cont").disabled ? '' : document.querySelector("#q_title_i_cont").value,
          event_title_or_event_slug_or_event_short_title_i_cont: document.querySelector("#q_event_title_or_event_slug_or_event_short_title_i_cont").disabled ? '' : document.querySelector("#q_event_title_or_event_slug_or_event_short_title_i_cont").value,
          event_event_managers_name_or_event_event_managers_email_i_cont: document.querySelector("#q_event_event_managers_name_or_event_event_managers_email_i_cont").disabled ? '' : document.querySelector("#q_event_event_managers_name_or_event_event_managers_email_i_cont").value
        })
      }))
      .then(res => {
        return res.text()
      }).then(data => {
        alert('Um email será enviado para você com o link para download.')
        event.target.style.pointerEvents = null
      })
    }
  }

  changeSearchType(event){
    this.searchTypeTarget.value = event.currentTarget.value
  }

  addEventEditionEventManager(event){
    if (document.getElementById('event_edition_event_id').value != ''){
      var itemsCount = document.querySelectorAll('.item').length
      var divItem = document.createElement("div")
      divItem.classList.add("item")

      var divFlex = document.createElement("div")
      divFlex.classList.add("flex")

      //Event Manager
      var divFlexItem = document.createElement("div")
      divFlexItem.classList.add("flex-item")
      divFlexItem.classList.add("form-group")
      var eventManagerLabel = document.createElement("label")
      eventManagerLabel.innerHTML = "Administrador"
      var eventManagerSpan = document.createElement("span")
      eventManagerSpan.innerHTML = ""
      eventManagerLabel.appendChild(eventManagerSpan)
      var eventManagerSelect = document.createElement("select")
      eventManagerSelect.setAttribute('data-action', 'change->application#changeSelect')
      eventManagerSelect.setAttribute('data-target', 'event-editions.eventManagerId')
      eventManagerSelect.name = "event_edition[event_editions_event_managers_manager_roles_attributes][" + itemsCount + "][event_manager_id]"
      divFlexItem.appendChild(eventManagerLabel)
      divFlexItem.appendChild(eventManagerSelect)
      this.loadEventManagers(new Choices(eventManagerSelect, this.choiceConfigWithoutRemoveItem))

      //Role
      var divFlexItem2 = document.createElement("div")
      divFlexItem2.classList.add("flex-item")
      divFlexItem2.classList.add("form-group")
      var roleLabel = document.createElement("label")
      roleLabel.innerHTML = "Papel"
      var roleSpan = document.createElement("span")
      roleSpan.innerHTML = ""
      roleLabel.appendChild(roleSpan)
      var roleSelect = document.createElement("select")
      roleSelect.classList.add("choice")
      roleSelect.setAttribute('data-action', 'change->application#changeSelect change->event-editions#changeRole')
      roleSelect.setAttribute('data-target', 'event-editions.role')
      roleSelect.name = "event_edition[event_editions_event_managers_manager_roles_attributes][" + itemsCount + "][role]"
      var roleBlank = document.createElement('option')
      roleBlank.innerHTML = ""
      var role0 = document.createElement('option')
      role0.value = 0
      role0.innerHTML = "Gestor de Evento"
      var role1 = document.createElement('option')
      role1.disabled = true
      role1.value = 1
      role1.innerHTML = "Avaliador de Trabalhos"
      roleSelect.appendChild(roleBlank)
      roleSelect.appendChild(role0)
      roleSelect.appendChild(role1)
      divFlexItem2.appendChild(roleLabel)
      divFlexItem2.appendChild(roleSelect)
      new Choices(roleSelect, this.choiceConfigWithoutRemoveItem)


      divFlex.appendChild(divFlexItem)
      divFlex.appendChild(divFlexItem2)
      divItem.appendChild(divFlex)

      var hr = document.createElement("hr")
      divItem.appendChild(hr)

      this.eventManagersWrapperTarget.append(divItem)
      this.removeEventEditionEventManagerButtonTarget.classList.remove("is-display-none")
    } else {
      alert("Você deve selecionar um evento antes de prosseguir. Clique na 'Etapa 1' e selecione um evento")
    }
  }

  removeAllEventEditionEventManager(){
    for (var i = 0; i <= document.querySelectorAll("div[data-target='event-editions.eventManagersWrapper'] .item").length; i++){
      this.removeEventEditionEventManager(true)
    }
  }

  removeEventEditionEventManager(a) {
    const child = this.eventManagersWrapperTarget.lastElementChild
    if (child == null) {
        return false
    } else {
      let answer = null
      if (a == true){
        answer = a
      } else {
        answer = this.removeEventManagerConfirmationBox()
      }
      if (answer) {
        this.eventManagersWrapperTarget.lastElementChild.remove()
      }
    }
    document.querySelectorAll("div[data-target='event-editions.eventManagersWrapper'] .item").length > 0 ? this.removeEventEditionEventManagerButtonTarget.classList.remove("is-display-none") : this.removeEventEditionEventManagerButtonTarget.classList.add("is-display-none")
  }

  removeEventManagerConfirmationBox() {
    let answer = window.confirm("Ao remover este avaliador de trabalho, o mesmo deixará de ter acesso... Tem certeza que deseja remover este avaliador de trabalho?")
    return answer
  }

  loadEventManagers(targetElementChoice) {
    let eventManagers = []
    document.querySelectorAll("[data-target='event-editions.eventManagerId']").forEach(e => {
        if (!(e.value == "") || (e.value == undefined)) {
          eventManagers.push(e.value)
        }
    })
    targetElementChoice.setChoices(function(callback) {
      return fetch(
        "/organizadores/listar-todos?event_id=" + document.getElementById('event_edition_event_id').value + "&except_ids=" + eventManagers + "&role=0"
      )
      .then(function(response) {
        return response.json()
      })
      .then(function(data) {
        return [{ value: '', label: '' }].concat(data.map(function(eventManager) {
          return { value: eventManager.value, label: eventManager.label }
        }))
      })
    })
  }

  changeEnableArticlesCategories(){
    if (this.enableArticlesCategoriesTarget.checked){
      this.articleCategoriesDivTarget.classList.remove("is-display-none")
    } else {
      this.articleCategoriesDivTarget.classList.add("is-display-none")
    }
  }

  changeArticlesCategoriesToEventEditionEventManager(){
    // console.log(this.eventEditionArticleCategories.getValue())
    // document.querySelectorAll('[id^="event_edition_event_editions_event_managers_attributes_"]').forEach(e => {
    //   if (e.id.includes('_article_category_ids')){
    //     new Choices(e, this.choiceConfig).setChoices(
    //       [{ value: '', label: '' }].concat(this.eventEditionArticleCategories.getValue())
    //     )
    //   }
    // })
  }

  changeRole(e){
    this.hideOrShowEventEditionEventManagerArticleCategoriesDiv(e.target, 'target')
  }

  checkVisibleEventEditionEventManagerArticleCategoriesDivs(origin){
    document.querySelectorAll('[id^="event_edition_event_editions_event_managers_attributes_"]').forEach(e => {
      if (e.id.includes('_role')){
        this.hideOrShowEventEditionEventManagerArticleCategoriesDiv(e, origin)
      }
    })
  }

  hideOrShowEventEditionEventManagerArticleCategoriesDiv(e, origin){
    const EventManagerArticleReviewerRole = '1'
    const TargetOrigin = 'target'
    const LoadOrigin = 'load'
    let targetElement = null
    if (origin == TargetOrigin){
      targetElement = e.parentElement.parentElement.parentElement.parentElement.nextElementSibling
    } else if (origin == LoadOrigin){
      targetElement = e.parentElement.parentElement.nextElementSibling
    }
    // if (e.value.toString() == EventManagerArticleReviewerRole) {
    //   targetElement.classList.remove("is-display-none")
    // } else {
    //   targetElement.classList.add("is-display-none")
    // }
  }

  applyAgreementSignTermDefaultValues() {
    let r = confirm(`Se o termo já estiver preenchido, será sobrescrito. Deseja prosseguir?`)
    if (r == true) {
      document.querySelector("#event_edition_agreement_sign_term").innerHTML = '<div>Por meio do presente instrumento venho AUTORIZAR, de forma gratuita e sem qualquer ônus, a utilização de minha imagem e voz no evento acima indicado, bem como lhe ceder todo e qualquer direito autoral e patrimonial dela decorrente.<br><br>A presente autorização alcança o uso da imagem e voz para os seguintes fins:&nbsp;<br><br>a) Transmissão no evento o qual ocorrerá de %{event_edition_date};&nbsp;<br>b) Divulgação, em todos os meios possíveis, sejam eles off-line ou on-line;&nbsp;<br>c) Disponibilização do conteúdo gravado aos participantes que optarem pelos produtos do evento;&nbsp;<br>d) Reapresentação/reprise do evento em período posterior à data acima citada, se for o caso;&nbsp;<br>e) Reutilização/reaproveitamento do conteúdo em outras edições do evento ou em eventos inéditos da área.<br><br>A presente autorização e cessão são outorgadas livre e espontaneamente, em caráter gratuito, não incorrendo à autorizada qualquer custo ou ônus, seja a que título for, sendo que estas são firmadas em caráter irrevogável, irretratável, e por prazo indeterminado.</div>'
    }
  }


  changeModality(){
    const ONLINE_MODALITY = "online"
    if (this.modalityTarget.value == ONLINE_MODALITY){
      this.addressDivTarget.classList.add("is-display-none")
    } else {
      this.addressDivTarget.classList.remove("is-display-none")
    }
  }

  loadCKEditor() {
    if (document.querySelector("#event_edition_certificate_communication")) {
      let toolbarConfig = [
        { name: 'basicstyles', items: [ 'Bold', 'Italic' ] }
      ]

      let config = {
        toolbar: toolbarConfig
      }

      if (CKEDITOR.instances.hasOwnProperty("event_edition_certificate_communication")) {
        CKEDITOR.instances.event_edition_certificate_communication.destroy()
      }
      CKEDITOR.replace(document.querySelector('#event_edition_certificate_communication'), config)
    }
  }
}
